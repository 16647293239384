.comprobantes-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .comprobantes-card {
    padding: 60px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
  }
  
  .comprobantes-header {
    color: #fd6b0c;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .comprobantes-button {
    background-color: #fd6b0c;
    border: none;
    margin-top: 20px;
  }
  
  .comprobantes-button:hover {
    background-color: #fd6b0c;
  }
  
  .table th, .table td {
    text-align: center;
    font-size: 1.2rem;
    padding: 15px;
  }
  
  .table th {
    background-color: #fd6b0c;
    color: white;
  }
  
  .table td {
    background-color: #f8f9fa;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    background-color: #fd6b0c;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .pagination button:hover{
    background-color: #ffd2c5;
    
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
  }
  
  .disabled-button {
    background-color: #cccccc !important;
    border-color: #cccccc !important;
    cursor: not-allowed;
  }

  .modal-custom {
    max-width: 80%;
  }
  
  .modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*.table tbody tr:hover td {
    color: #FF5100;
    cursor: pointer;
  }*/

  .table td button {
    color: #118a80;
    text-decoration: none;
  }
  
  .table td button:hover {
    color: #fd6b0c;
    cursor: pointer;
  }

  .ButtonsComprobante:hover{
    background-color: #ffd2c5;
    color: #fd6b0c;
    border: none;
  }

  .ButtonsComprobante{
    background-color: #118a80;
    border: none;
    margin-top: 20px;
  }

 