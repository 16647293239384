.mi-comercio-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .mi-comercio-card {
    padding: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 990px;
   
  }
  
  .mi-comercio-header {
    color:  #118a80;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .mi-comercio-img {
    width: 350px; 
    height: auto;
    margin-bottom: 20px;
  }
  
  .mi-comercio-info {
    text-align: left;
    font-size: 1.2rem;
  }
  
  .mi-comercio-info p {
    margin-bottom: 10px;
  }
  
  .mi-comercio-info p strong {
    color: #fd6b0c;
  }
  