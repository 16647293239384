.agregar-button {
  background-color: #118a80;
  border: none;
  
}

.agregar-button:hover {
  background-color: #ffd2c5;
    color: #fd6b0c;
}

.agregar-button:active {
  background-color: #fd6b0c !important; 
  color: #ffffff; 
}
.agregar-button:disabled {
  background-color: #ccc !important; 
  color: #ffffff; 
}
    
  .pagination-button {
    background-color: #118a80;
    border: none;
    margin-top: 20px;
  }
  
  .pagination-button:hover {
    background-color: #ffd2c5;
      color: #fd6b0c;
  }
  
  .pagination-button.disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }

  .custom-button {
  width: 130px; 
  height: 40px !important; 
  background-color: #118a80;
  border: none;
}

.custom-button:hover {
  background-color: #ffd2c5;
  color: #fd6b0c;
}

.custom-button:active {
  background-color: #fd6b0c !important;
  color: #ffffff;
}

.custom-button {
  width: 140px; /* Ajusta el ancho para ambos botones */
  height: 50px; /* Ajusta la altura para ambos botones */
  background-color: #118a80;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button:hover {
  background-color: #ffd2c5;
  color: #fd6b0c;
}

.custom-button:active {
  background-color: #fd6b0c !important;
  color: #ffffff;
}

.icon-button {
  
  background-color: #118a80;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: #ffd2c5;
  color: #fd6b0c;
}

.icon-button:active {
  background-color: #fd6b0c !important;
  color: #ffffff;
}
.custom-button-lupa {
  height: 38px; 
  width: 40px; 
  padding: 0; 
  display: flex;
  justify-content: center; 
  align-items: center;
  background-color: #118a80;
  border:none;
}


.custom-button-lupa:hover {
  background-color: #ffd2c5;
  color: #fd6b0c;
  border:none;
}

.custom-button-lupa:active {
  background-color: #fd6b0c !important;
  color: #ffffff;
  border:none;
}
