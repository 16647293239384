.sidebar-wrapper {
  background-color: #f8f9fa;
  display: flex;
}

.sidebar {
  width: 200px;
  position: fixed;
  top: 70px; 
  left: 0;
  height: calc(100vh - 70px); 
  background-color: #fd6b0c;
  padding-top: 15px;
  box-shadow: 2px 0 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sidebar .nav-link {
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-size: 1rem;
  display: flex;
  align-items: center; 
}

.sidebar .nav-link:hover {
  color: #FF5100;
  background-color: #ffff;
  transform: translateX(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sidebar .nav-link:active {
  background-color: #118a80;
}

.sidebar .nav-link.disabled {
  color: #6c757d;
  cursor: not-allowed;
}

.sidebar .nav-link.disabled:hover {
  background-color: transparent;
  color: #6c757d;
}

.sub-option {
  margin-left: 20px; 
  background-color: #ffff;
  color: #FF5100 !important;
  border-radius: 0 !important;
}

.nav-link .fa-icon {
  margin-right: 10px;
}
