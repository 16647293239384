.lista-saldos-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.lista-saldos-card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin-top: 30px;
}

.link-ver-mas {
  color: #118a80;
  text-decoration: none;
  cursor: pointer;
}

.link-ver-mas:hover {
  color: #ff7043;
  text-decoration: underline;
}



.lista-saldos-header {
  color: #fd6b0c;
  font-size: 2rem;
  margin-bottom: 20px;
}

.lista-saldos-table th, .lista-saldos-table td {
  text-align: center;
  font-size: 1.2rem;
  padding: 15px;
}

.lista-saldos-table th {
  background-color: #fd6b0c;
  color: white;
}

.lista-saldos-table td {
  background-color: #f8f9fa;
}

.lista-saldos-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lista-saldos-buttons .btn {
  background-color: #fd6b0c;
  border: none;
}

.lista-saldos-buttons .btn:hover {
  background-color: #ffd2c5;
    color: #fd6b0c;
}

.text-danger {
  color: red;
  font-size: 1rem;
}

.detalle-saldo-table {
  width: 100%;
  margin: 0 auto;
}

.detalle-saldo-table td {
  padding: 10px;
}

.detalle-saldo-table strong {
  color: #fd6b0c;
}