.confirm-modal-header {
 color:#fd6b0c;
 text-align: center;
}

.confirm-modal-title {
    text-align: center;
}

.confirm-modal-footer {
  display: flex;
  justify-content: space-between;
}

.confirm-modal-body {
  font-size: 1.2rem;
  padding: 20px;
  text-align: center;
}

.confirm-modal-button {
  background-color: #118a80;
  border: none;
}

.confirm-modal-button:hover {
  background-color: #ff7043;
  color: white;
}
