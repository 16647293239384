body {
  font-family: 'Arial', sans-serif;
}

.inicio-container {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.inicio-card {
  width: 100%; 
  padding: 60px;
  border: none;
  border-radius: 25px; 
  background-color: #ffffff; 
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  min-height: 500px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.inicio-card::before, .inicio-card::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: rgba(17, 138, 128, 0.1);
}

.inicio-card::before {
  width: 200px;
  height: 200px;
  top: -50px;
  left: -50px;
}

.inicio-card::after {
  width: 250px;
  height: 250px;
  bottom: -100px;
  right: -100px;
  background: rgba(17, 138, 128, 0.2);
}

.circle-orange {
  position: absolute;
  border-radius: 50%;
  background: rgba(253, 107, 12, 0.8);
}

.circle-orange-1 {
  width: 30px;
  height: 30px;
  top: 40%;
  left: 15%;
}

.circle-orange-2 {
  width: 50px;
  height: 50px;
  bottom: 15%;
  right: 10%;
}

.image-column {
  position: relative;
  z-index: 1;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.text-column {
  position: relative;
  z-index: 2;
  padding: 20px;
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: center;
}

.inicio-img {
  max-width: 300px; 
  border-radius: 0;  
  border: none;  
  box-shadow: none;  
}

.inicio-header {
  font-weight: bold;
  color: #fd6b0c;
  margin-bottom: 20px;
  font-size: 2rem; 
}

.info-text {
  font-size: 1.2rem; 
  color: #333;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.info-icon {
  margin-right: 10px;
  color: #118a80;
}

.info-data {
  margin-left: 8px;
}


.detalle-column {
  flex: 0 0 50%; /* Hace que cada columna ocupe el 50% del ancho del contenedor */
  max-width: 50%; /* Limita el ancho máximo de cada columna al 50% */
  padding: 0 15px; /* Añade un poco de espacio a los lados */
}

.detalle-text {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.detalle-icon {
  margin-right: 8px;
  color: #118a80;
  min-width: 24px;
}

.long-text {
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
  max-width: 250px; 
  display: inline-block; 
}

.caja-button {
  background-color: #118a80;
  border: none;
  margin-top: 20px;
}

.caja-button:hover {
  background-color: #ffd2c5;
    color: #fd6b0c;
}

.caja-button:active {
  background-color: #fd6b0c !important; /* Cambia el fondo cuando se presiona */
  color: #ffffff; /* Cambia el color del texto cuando se presiona */
}