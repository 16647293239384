/*.saldo-container {
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.saldo-image {
  width: 600px;
  height: auto;
}

.saldoh2 {
  color: #fd6b0c;
  font-size: 2rem;
  margin-bottom: 20px;
}

/*.saldo-card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 800px; 
  margin: 0 auto; 
}*/

.saldo-table {
  margin-top: 20px;
}

.saldo-table th, .saldo-table td {
  text-align: center;
  font-size: 1.2rem;
  padding: 15px;
}



.saldo-table td {
  background-color: #f8f9fa;
}

.loading-text {
  font-size: 1.5rem;
  color: #666;
}