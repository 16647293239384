.lista-de-cajas-card {
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 110%;
}

.lista-de-cajas-header {
  color: #fd6b0c;
  font-size: 2rem;
}

.lista-de-cajas-table th, .lista-de-cajas-table td {
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
}

.lista-de-cajas-table th {
  background-color: #fd6b0c;
  color: white;
}

.lista-de-cajas-table td {
  background-color: #f8f9fa;
}

.lista-de-cajas-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lista-de-cajas-buttons .btn {
  background-color: #118a80;
  border: none;
}

.lista-de-cajas-buttons .btn:hover {
  background-color: #ffd2c5;
    color: #fd6b0c;
}

btn-link:hover{
  background-color: none;
}