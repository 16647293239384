.pagination-button {
    background-color: #118a80;
    border: none;
    margin-top: 20px;
  }
  
  .pagination-button:hover {
    background-color: #ffd2c5;
      color: #fd6b0c;
  }
  
  .pagination-button.disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
  
  .Btn-ver-historico{
    background-color: #118a80;
    border: none;
    margin-top: 20px;
  }
  
  .Btn-ver-historico:hover {
    background-color: #ffd2c5;
      color: #fd6b0c;
  }
  
  .Btn-ver-historico:active {
    background-color: #fd6b0c !important; /* Cambia el fondo cuando se presiona */
    color: #ffffff; /* Cambia el color del texto cuando se presiona */
  }