.lista-sucursales-container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.lista-sucursales-card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin-top: 30px;
}

.lista-sucursales-header {
  color: #fd6b0c;
  font-size: 2rem;
  margin-bottom: 20px;
}

.lista-sucursales-table th, .lista-sucursales-table td {
  text-align: center;
  font-size: 1.2rem;
  padding: 15px;
}

.lista-sucursales-table th {
  background-color: #fd6b0c;
  color: white;
}

.lista-sucursales-table td {
  background-color: #f8f9fa;
}

.text-danger {
  color: red;
  font-size: 1rem;
}
