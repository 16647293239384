
.logout-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
  
  .logout-card {
    padding: 40px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    background-color: #ffffff;
  }
  
  .logout-image {
    width: 300px;
    height: auto;
  }
  
  .logout-header {
    color: #fd6b0c;
    font-size: 1.5rem;
  }
  
  .logout-button {
    background-color: #118a80;
    border: none;
    margin-top: 20px;
    width: 100%;
  }
  
  .logout-button:hover {
    background-color: #ff4343;
  }
  