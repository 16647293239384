.recarga-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .recarga-card {
    padding: 60px;
    padding-left: 80px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
  }
  
  .recarga-header {
    color: #fd6b0c;
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .recarga-exito {
    color: #fd6b0c;
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .recarga-button {
    background-color: #fd6b0c;
    border: none;
    margin-top: 20px;
  }
  
  .recarga-button:hover {
    background-color: #ffd2c5;
    color: #fd6b0c;
  }
  
  .recarga-button-confirm{
    background-color:  #118a80 !important;
    border: none;
    margin-top: 15px;
  }