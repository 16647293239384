.code-inputs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .code-input {
    font-size: 1.5rem;
    text-align: center;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
  }
  