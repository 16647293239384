.caja-container {
  width: 900px;
  margin: 0 auto;
  margin-top: 35px;
}

.caja-image {
  max-width: 400px; 
  height: auto; 
}

.caja-card {
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 110%;
  margin-bottom: 20px; 
}

.caja-header {
  color: #fd6b0c;
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 15px;
}

.caja-subheader {
  color: #fd6b0c;
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 15px;
}

.caja-button {
  background-color: #118a80;
  border: none;
  margin-top: 20px;
}

.caja-button:hover {
  background-color: #ffd2c5;
    color: #fd6b0c;
}
