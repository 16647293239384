.todas-cajas-container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .todas-cajas-card {
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 95%;
    
  }
  
  .todas-cajas-header {
    color: #fd6b0c;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .todas-cajas-table th, .todas-cajas-table td {
    text-align: center;
    font-size: 1.2rem;
    padding: 15px;
  }
  
  .todas-cajas-table th {
    background-color: #fd6b0c;
    color: white;
  }
  
  .todas-cajas-table td {
    background-color: #f8f9fa;
  }
  
  .link-ver-mas {
    color: #118a80;
    text-decoration: none;
    cursor: pointer;
  }
  
  .link-ver-mas:hover {
    color: #ff7043;
    text-decoration: underline;
  }
  