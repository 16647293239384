.confirmation-modal .modal-dialog {
    transform: translateY(-30%);
    transition: transform 0.3s ease-in-out;
  }

.confirmation-modal .modal-header {
    background-color: #f8f9fa;
    border-bottom: none;
  }
  
  .confirmation-modal .modal-title {
    color: #fd6b0c;
    font-size: 1.5rem;
  }
  
  .confirmation-modal .modal-body {
    font-size: 1.2rem;
    color: #333;
  }
  
  .confirmation-modal .modal-text {
    margin-bottom: 10px;
  }
  
  .confirmation-modal .modal-info {
    margin-bottom: 5px;
  }
  
  .confirmation-modal .modal-monto {
    padding-top: 10px;
    font-size: 1.5rem;
    
  }
  .confirmation-modal .modal-cuit {
    padding-top: 10px;
 
    
  }
  
  .confirmation-modal .modal-footer {
    border-top: none;
  }
  
  .confirmation-modal .modal-button {
    background-color: #fd6b0c;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
  }
  .confirmation-modal .modal-button-cancel {
    background-color: #8a8481;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
  }

  .confirmation-modal .modal-button:hover {
    background-color: #ff7043;
  }
  