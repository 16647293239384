
.main-content {
  margin-top: 60px; 
  padding: 20px;
}


.navbar-toggler {
  border: none;
  outline: none;
  background-color: orange;
  
  
}

.navbar-brand.hamburger-menu {
  position: absolute;
  left: 10px;
  
  
}

.navbar-brand.logo-container {
  position: absolute;
  left: 50%;
  
  
  transform: translateX(-50%);
}

.navbar-collapse {
  justify-content: flex-end;
  
}

.navbar-nav .nav-link {
  display: flex;
  align-items: center;
}

.username-display {
  margin-left: 15px;
  font-weight: bold;
}


