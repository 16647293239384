.header-navbar {
    background-color: #f8f9fa;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-col {
    margin-left: 13px; 
  }
  